import { omit } from 'lodash'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { RootState } from 'store/store'
import { apiClient } from 'utils/api'
import { RequestError, RequestStatus } from 'interfaces/common.interface'
import { Applicant, ApplicantResponseBody, ApplicantStatus, ApplicantDocuments } from 'interfaces/applicant.interface'
import { AuthorizationDocument } from 'interfaces/authorization_document.interface'
import {
  ApplicantVerification,
  ApplicantVerificationType,
  ApplicantVerificationTypeConsent,
  AutomatedVerificationCheck,
  CheckType
} from 'shared_components/ApplicantVerification/interfaces/automated_verification.interface'
import { verificationSource } from 'shared_components/ApplicantVerification/utils/verification_source_helper'

export interface ApplicantState {
  applicant: Applicant
  applicantStatus: RequestStatus
  applicantInitialLoading: boolean
  applicantError: RequestError | undefined
  applicantVerification: ApplicantVerification | null
  applicantVerificationStatus: RequestStatus
  applicantVerificationError: RequestError | undefined
  availableVerificationTypes: AvailableVerificationTypesResponse[]
  availableVerificationTypesStatus: RequestStatus
  availableVerificationTypesError?: RequestError
  applicantDocuments: ApplicantDocuments[]
  applicantDocumentsStatus: RequestStatus
  applicantDocumentsError: RequestError | undefined
}

const initialState: ApplicantState = {
  applicant: {
    first_name: '',
    last_name: '',
    has_ssn: true,
    primary_id_document: {
      type: '',
      id_number: '',
      issuing_country: '',
      issuing_state: '',
      expiration_date: null
    },
    secondary_id_document: { type: '' },
    home_address: {
      address_line_1: '',
      address_line_2: '',
      city: '',
      state: '',
      zip_code: '',
      country: 'US'
    },
    phone_number: '',
    status: ApplicantStatus.Unverified,
    flow_version: 'v2'
  },
  applicantStatus: RequestStatus.Pending,
  applicantInitialLoading: true,
  applicantError: undefined,
  applicantVerification: null,
  applicantVerificationStatus: RequestStatus.Pending,
  applicantVerificationError: undefined,
  availableVerificationTypes: [],
  availableVerificationTypesStatus: RequestStatus.Pending,
  availableVerificationTypesError: undefined,
  applicantDocuments: [],
  applicantDocumentsStatus: RequestStatus.Pending,
  applicantDocumentsError: undefined
}

interface CreateVerificationParams {
  accountId: number
  recipientId: string
  type: ApplicantVerificationType
  allowUseBiometricData?: boolean
}

interface FetchApplicantVerification {
  accountId: number
  recipientId: string
}

interface FetchAvailableVerificationTypesParams {
  accountId: number
}

interface FetchApplicantDocumentsParams {
  accountId: number
}

interface AvailableVerificationTypesResponse {
  type: ApplicantVerificationType
  consent?: { type: ApplicantVerificationTypeConsent }
}

export const fetchApplicant = createAsyncThunk('applicant/fetchApplicant', async () => {
  try {
    const response: { data: ApplicantResponseBody } = await apiClient.get('mail-authorization/applicant')
    return response.data
  } catch (e: any) {
    const error: AxiosError = { ...e }
    if (error.response?.status === 404) return initialState.applicant
    throw e
  }
})

export const fetchAvailableVerificationTypes = createAsyncThunk(
  'applicant/fetchAvailableVerificationTypes',
  async ({ accountId }: FetchAvailableVerificationTypesParams) => {
    const response: {
      data: AvailableVerificationTypesResponse[]
    } = await apiClient.get(`mail-authorization/accounts/${accountId}/applicant/verification_types_details`)
    return response.data
  }
)

export const createApplicantVerification = createAsyncThunk(
  'applicant/createApplicantVerification',
  async (
    { accountId, recipientId, type, allowUseBiometricData = false }: CreateVerificationParams,
    { rejectWithValue }
  ) => {
    const data = {
      type,
      terms_and_conditions: {
        allow_use_biometric_data: allowUseBiometricData
      },
      source: verificationSource()
    }
    try {
      const response = await apiClient.post(
        `mail-authorization/accounts/${accountId}/recipients/${recipientId}/verification`,
        data
      )
      return response.data
    } catch (e: any) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const fetchApplicantVerification = createAsyncThunk(
  'applicant/fetchApplicantVerification',
  async ({ accountId, recipientId }: FetchApplicantVerification) => {
    try {
      const response: { data: ApplicantVerification } = await apiClient.get(
        `mail-authorization/accounts/${accountId}/recipients/${recipientId}/verification`
      )
      return response.data
    } catch (e: any) {
      const error: AxiosError = { ...e }
      if (error.response?.status === 404) return initialState.applicantVerification
      throw e
    }
  }
)

export const fetchApplicantDocuments = createAsyncThunk(
  'applicant/fetchApplicantDocuments',
  async ({ accountId }: FetchApplicantDocumentsParams) => {
    try {
      const response: { data: ApplicantDocuments[] } = await apiClient.get(
        `mail-authorization/accounts/${accountId}/applicant/documents`
      )
      return response.data
    } catch (e: any) {
      const error: AxiosError = { ...e }
      if (error.response?.status === 404) return initialState.applicantDocuments
      throw e
    }
  }
)

export const applicantSlice = createSlice({
  name: 'applicant',
  initialState,
  reducers: {
    clearApplicantVerification(state) {
      state.applicantVerification = initialState.applicantVerification
      state.applicantVerificationStatus = initialState.applicantVerificationStatus
      state.applicantVerificationError = initialState.applicantVerificationError
    },
    updateApplicantVerification(state, action: PayloadAction<ApplicantVerification>) {
      state.applicantVerification = action.payload
      state.applicantVerificationError = initialState.applicantVerificationError
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchApplicant.fulfilled, (state, action) => {
        state.applicantStatus = RequestStatus.Success
        // Omitting the issuing_entity field since it's not used anymore in the UI
        state.applicant = omit(action.payload, 'primary_id_document.issuing_entity')
        state.applicantInitialLoading = false
        state.applicantError = undefined
      })
      .addCase(fetchApplicant.pending, state => {
        state.applicantStatus = RequestStatus.Pending
      })
      .addCase(fetchApplicant.rejected, (state, action) => {
        state.applicantStatus = RequestStatus.Error
        state.applicant = initialState.applicant
        state.applicantError = action.error.message
      })
      .addCase(fetchApplicantVerification.fulfilled, (state, action) => {
        state.applicantVerificationStatus = RequestStatus.Success
        state.applicantVerification = action.payload
        state.applicantVerificationError = undefined
      })
      .addCase(fetchApplicantVerification.pending, state => {
        state.applicantVerificationStatus = RequestStatus.Pending
      })
      .addCase(fetchApplicantVerification.rejected, (state, action) => {
        state.applicantVerificationStatus = RequestStatus.Error
        state.applicantVerification = initialState.applicantVerification
        state.applicantVerificationError = action.error.message
      })
      .addCase(createApplicantVerification.fulfilled, (state, action) => {
        state.applicantVerificationStatus = RequestStatus.Success
        state.applicantVerification = action.payload
        state.applicantVerificationError = undefined
      })
      .addCase(createApplicantVerification.pending, state => {
        state.applicantVerificationStatus = RequestStatus.Pending
      })
      .addCase(createApplicantVerification.rejected, (state, action) => {
        state.applicantVerificationStatus = RequestStatus.Error
        state.applicantVerification = initialState.applicantVerification
        state.applicantVerificationError = action.error.message
      })
      .addCase(fetchAvailableVerificationTypes.fulfilled, (state, action) => {
        state.availableVerificationTypes = action.payload
        state.availableVerificationTypesStatus = RequestStatus.Success
        state.availableVerificationTypesError = undefined
      })
      .addCase(fetchAvailableVerificationTypes.rejected, (state, action) => {
        state.availableVerificationTypes = []
        state.availableVerificationTypesStatus = RequestStatus.Error
        state.availableVerificationTypesError = action.error.message
      })
      .addCase(fetchAvailableVerificationTypes.pending, state => {
        state.availableVerificationTypesStatus = RequestStatus.Pending
      })
      .addCase(fetchApplicantDocuments.fulfilled, (state, action) => {
        state.applicantDocuments = action.payload
        state.applicantDocumentsStatus = RequestStatus.Success
        state.applicantDocumentsError = undefined
      })
      .addCase(fetchApplicantDocuments.rejected, (state, action) => {
        state.applicantDocuments = []
        state.applicantStatus = RequestStatus.Error
        state.applicantError = action.error.message
      })
  }
})

export const { clearApplicantVerification, updateApplicantVerification } = applicantSlice.actions

export const getApplicant = (state: RootState): Applicant => state.applicant.applicant
export const getApplicantVerification = (state: RootState): ApplicantVerification | null =>
  state.applicant.applicantVerification
export const isApplicantReady = (state: RootState): boolean => state.applicant.applicantStatus === RequestStatus.Success
export const isApplicantVerificationReady = (state: RootState): boolean =>
  state.applicant.applicantVerificationStatus === RequestStatus.Success
export const getApplicantVerificationStatus = (state: RootState): RequestStatus =>
  state.applicant.applicantVerificationStatus
export const getHasApplicant = (state: RootState): boolean => state.applicant.applicant.created_at !== undefined
export const getApplicantStatus = (state: RootState): ApplicantStatus | undefined => state.applicant.applicant.status
export const getIsApplicantVerified = (state: RootState): boolean =>
  state.applicant.applicant.status === ApplicantStatus.Verified
export const getIsApplicantInitialLoading = (state: RootState): boolean => state.applicant.applicantInitialLoading
export const getVerificationType = (state: RootState): ApplicantVerificationType | undefined =>
  state.applicant.applicantVerification?.type
export const getAutomatedVerificationChecksList = (state: RootState): AutomatedVerificationCheck[] =>
  state.applicant.applicantVerification?.checks ?? []
export const getSortedAutomatedVerificationChecksList = (state: RootState): AutomatedVerificationCheck[] => {
  const orderedSteps = [CheckType.IdentityVerification, CheckType.ProofOfAddress, CheckType.FormAuthorization]
  const checksList = getAutomatedVerificationChecksList(state)
  return [...checksList].sort((a, b) => orderedSteps.indexOf(a.type) - orderedSteps.indexOf(b.type))
}
export const getAutomatedVerificationCheck =
  (checkType: CheckType) =>
  (state: RootState): AutomatedVerificationCheck | undefined =>
    getAutomatedVerificationChecksList(state).find(({ type }) => type === checkType)
export const getAutomatedVerification1583Forms = (state: RootState): AuthorizationDocument[] | undefined =>
  state.applicant.applicantVerification?.forms
export const getAvailableVerificationTypes = (state: RootState): AvailableVerificationTypesResponse[] =>
  state.applicant.availableVerificationTypes
export const getApplicantDocuments = (state: RootState): ApplicantDocuments[] => state.applicant.applicantDocuments
export const areApplicantDocumentsReady = (state: RootState): boolean =>
  state.applicant.applicantDocumentsStatus === RequestStatus.Success

export default applicantSlice.reducer
